import React from "react";

const App = () => (
  <div className="app" style={{ height: "100vh" }}>
    <marquee scrollamount="20">NEVER GONNA GIVE YOU UP, NEVER GONNA LET YOU DOWN</marquee>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <img
        src="./rickroll-roll.gif"
        height={"500px"}
        width={"500px"}
        alt="hehe"
      />
    </div>
  </div>
);

export default App;
